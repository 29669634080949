import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { withLocale } from '../context/l10nContext'
import { TranslateString as t } from '../utils/DictionaryUtils'
import { LoadingSpinner } from './ui/LoadingSpinner'

const Loader = ({ locale, className, noMargin }) => {
    return (
        <Wrapper className={className} noMargin={noMargin}>
            <LoadingSpinner boxSize={33} />
            {t(locale, 'ui__general--loading')}...
        </Wrapper>
    )
}

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    ${({ noMargin }) => (!noMargin ? 'margin: 20px;' : 'padding: 10px')};
`

Loader.propTypes = {
    locale: PropTypes.string.isRequired,
    className: PropTypes.string,
    noMargin: PropTypes.bool,
}

export default withLocale(Loader)
