import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Fonts } from '../../styles/StyleConf'

const CharCounter = ({ count, limit }) => {
    return (
        <Wrapper>
            {count} / {limit}
        </Wrapper>
    )
}

const Wrapper = styled.span`
    font-size: ${Fonts.small};
    font-weight: bold;
    margin-left: 1em;
    white-space: nowrap;
`

CharCounter.defaultProps = {
    count: 0,
}

CharCounter.propTypes = {
    count: PropTypes.number,
    limit: PropTypes.number.isRequired,
}

export default CharCounter
