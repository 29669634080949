import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Svg from '../ui/Svg'
import { Colors, Fonts, UI } from '../../styles/StyleConf'
import Button from '../input/Button'
import Notification from '../ui/Notification'
import Config from '../../config/Config'
import { withLocale } from '../../context/l10nContext'
import { TranslateString as t } from '../../utils/DictionaryUtils'
import { withSettings } from '../../context/SettingsContext'
import { Link } from 'react-router-dom'
import { getLoginPath, sendRest } from '../../utils/APIUtils'

const styles = {
    height: 38,
    widthMobile: 48,
    maxWidth: 180,
    minWidth: 80,
}

const Wrapper = styled.div`
    position: relative;
    display: ${props => (props.mobileNavi ? 'none' : 'inline-block')};

    @media (min-width: ${Config.breakpoints.tablet}px) {
        display: inline-block;
    }
`
const MenuBtn = styled(Button)`
    && {
        &:active {
            outline: none;
        }

        position: relative;
        padding: 0 0.5em;
        background-color: ${Colors.brandWhite};
        border-color: ${Colors.uiGray};
        overflow: visible;
        margin: 0 0.5em;
        width: ${styles.widthMobile}px;
        height: ${styles.height}px;
        ${props => props.open && 'border-radius: 3px 3px 0 0;'};
        justify-content: space-between;

        @media (min-width: ${Config.breakpoints.tablet}px) {
            min-width: ${styles.minWidth}px;
            min-width: ${styles.minWidth}px;
        }
    }
`
const Avatar = styled(Svg).attrs({
    type: 'user',
})`
    fill: ${Colors.brandBlack};
    padding: 0.5em;
`
const Name = styled.div`
    color: ${Colors.brandBlack};
    font-weight: normal;
    max-width: 8em;
    overflow-x: hidden;
    text-overflow: ellipsis;
    display: none;

    @media (min-width: ${Config.breakpoints.tablet}px) {
        display: inline-block;
    }
`
const Arrow = styled(Svg)`
    fill: ${Colors.brandBlack};
    margin: 0 0.5em;
    display: none;

    @media (min-width: ${Config.breakpoints.tablet}px) {
        display: inline-block;
    }
`
const Menu = styled.ul`
    margin: 0;
    padding: 0;
    list-style: none;
    position: absolute;
    top: ${styles.height - 1}px;
    right: 7px;
    width: ${styles.width}px;
    border: 1px solid ${Colors.uiGray};
    background-color: ${Colors.brandWhite};
    z-index: ${UI.zMenuOption};

    @media (min-width: ${Config.breakpoints.mobile}px) {
        right: 7px;
        left: auto;
    }
`
const Option = styled.li`
    a {
        &:hover,
        &:active {
            background-color: ${Colors.brandBlueLight};
            color: ${Colors.brandWhite};
            ${Fonts.linkHover};
        }

        padding: 1em;
        display: block;
        color: ${Colors.brandBlack};
        text-decoration: none;
        text-transform: uppercase;
        font-size: ${Fonts.small};
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
`

const UserMenu = ({ settings, active, toggleMenu, mobileNavi, locale, username, disabled }) => {
    const notifications = null
    const logoutPath = getLoginPath().replace('login', 'logout')

    return (
        <Wrapper mobileNavi={mobileNavi}>
            <MenuBtn onClick={toggleMenu} open={active} disabled={disabled}>
                <Avatar />
                {username && <Name>{username}</Name>}
                <Arrow type={`angle-${active ? 'up' : 'down'}`} />
                {!disabled && notifications && <Notification label="42" />}
            </MenuBtn>
            {active && (
                <Menu>
                    <Option>
                        <Link
                            to={`/${settings.pdx.name}${locale === 'fi' ? '' : '/' + locale}/persons/${
                                settings.user.id
                            }`}
                        >
                            {t(locale, 'ui__user-menu--profile')}
                        </Link>
                    </Option>
                    <Option>
                        <a href={logoutPath}>{t(locale, 'ui__general--logout')}</a>
                    </Option>
                </Menu>
            )}
        </Wrapper>
    )
}

UserMenu.propTypes = {
    settings: PropTypes.shape({
        pdx: PropTypes.shape({
            name: PropTypes.string.isRequired,
        }).isRequired,
    }),
    active: PropTypes.bool.isRequired,
    toggleMenu: PropTypes.func.isRequired,
    mobileNavi: PropTypes.bool.isRequired,
    locale: PropTypes.string,
    username: PropTypes.string,
    disabled: PropTypes.bool.isRequired,
}

export default withSettings(withLocale(UserMenu))
