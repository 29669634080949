import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { Colors, Fonts, Headings } from '../../styles/StyleConf'

const Navigation = ({ type, children, className }) => {
    const linkColors = {}
    let theme
    switch (type) {
        case 'footer':
            linkColors.link = Colors.brandWhite
            linkColors.active = Colors.brandWhite
            theme = css`
                text-transform: uppercase;
                text-align: right;
                line-height: 0.5;

                li {
                    background-color: ${Colors.brandBlack};
                    a {
                        padding-right: 0.5em;
                        padding-left: 0.5em;
                    }
                }
            `
            break
        case 'sidebar':
            linkColors.link = Colors.brandBlue
            linkColors.active = Colors.brandBlack
            theme = css`
                background-color: ${Colors.brandWhite};
                padding-bottom: 0.8em;

                li {
                    & > a,
                    & > span,
                    & > button {
                        line-height: 32px;
                        padding: 0 1em;
                        font-size: ${Fonts.medium};
                        font-weight: 600;
                    }

                    li:last-of-type {
                        margin-bottom: 1em;
                    }

                    display: block;
                    background-color: ${Colors.brandWhite};

                    &.first {
                        margin-top: 0.5em;
                    }
                }
            `
            break
        default:
            linkColors.link = Colors.brandBlue
            linkColors.active = Colors.brandBlack
            theme = css`
                padding-top: 1em;
                text-transform: uppercase;

                li:first-of-type a {
                    padding-left: 0;
                }
                li:last-of-type a {
                    padding-right: 0;
                }
            `
    }

    return (
        <List id="navigation__main" className={className} linkColors={linkColors} theme={theme}>
            {children}
        </List>
    )
}

Navigation.propTypes = {
    type: PropTypes.string,
    children: PropTypes.array.isRequired,
    className: PropTypes.string,
}

const List = styled.ul`
    font-size: ${Fonts.medium};
    font-weight: bold;
    list-style: none;
    margin: 0;
    padding: 0;

    li {
        &.heading {
            ${Headings.blockHeading};
        }
        &.navigation__main--subitem {
            &:first-of-type {
                padding-left: 1em;
            }
            &:hover {
                a,
                span {
                    ${Fonts.linkHover};
                }
            }

            display: block;
            background: ${Colors.brandWhite};
            border: 1px solid ${Colors.uiGray};
            border-top: 0;
        }

        color: ${props => props.linkColors.link};
        display: inline-block;
    }

    li.active,
    li.active > a,
    li.active > span {
        color: ${props => props.linkColors.active};
    }

    li > a,
    li > span,
    li > button {
        &:hover {
            ${Fonts.linkHover};
            cursor: pointer;
        }

        &:active {
            ${Fonts.linkActive};
        }

        display: block;
        color: ${props => props.linkColors.link};
        text-decoration: none;
        padding: 0.7em 1em;
        word-wrap: break-word;
    }

    ${props => props.theme};
`

List.propTypes = {
    linkColors: PropTypes.shape({
        active: PropTypes.string.isRequired,
        link: PropTypes.string.isRequired,
    }),
    theme: PropTypes.array.isRequired,
}

export default Navigation
