import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { UI, Colors, Fonts } from './styles/StyleConf'
import Config from './config/Config'

class Modal extends React.Component {
    constructor(props) {
        super(props)

        this.modalRoot = document.getElementById(Config.ui.modalId)
        this.el = document.createElement('div')
        this.wrapClass = 'modal__seranwrap'
    }

    componentDidMount() {
        document.body.classList.add(Config.ui.noScrollClass)
        this.modalRoot.appendChild(this.el)
    }

    componentWillUnmount() {
        document.body.classList.remove(Config.ui.noScrollClass)
        this.modalRoot.removeChild(this.el)
    }

    render() {
        const { children, closeModal } = this.props

        return ReactDOM.createPortal(
            <Wrapper className={this.wrapClass}>
                <Inner>
                    <CloseBtn onClick={() => closeModal({})}>╳</CloseBtn>
                    <Content>{children}</Content>
                </Inner>
            </Wrapper>,
            this.el
        )
    }
}

const sizes = {
    width: {
        narrow: '100%',
        wide: '700px',
    },
}
const Wrapper = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: ${UI.zShinkwrap};
    height: 1000vh;
    background-color: rgba(0, 0, 0, 0.3);
    cursor: pointer;
`
const Inner = styled.div`
    width: ${sizes.width.narrow};
    max-height: 96vh;
    position: fixed;
    top: 2vw;
    left: 0;
    background: ${Colors.brandWhite};
    border-radius: 3px;
    cursor: default;

    @media (min-width: ${Config.breakpoints.tablet}px) {
        width: ${sizes.width.wide};
        left: calc(50% - 350px);
    }

    @media (min-height: 600px) {
        max-height: 90vh;
        top: 5vh;
    }
`
const Content = styled.div`
    overflow-x: hidden;
    overflow-y: auto;
    width: 100%;
    padding: 50px 20px 20px;
    max-height: 96vh;

    @media (min-height: 600px) {
        max-height: 90vh;
    }
`
const CloseBtn = styled.div`
    &:hover {
        color: ${Colors.uiGray};
        cursor: pointer;
    }

    position: absolute;
    top: 20px;
    right: 20px;
    width: 15px;
    height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: ${Fonts.large};
    font-weight: bold;
    color: ${Colors.brandBlack};
    user-select: none;
    padding: 15px;
`

Modal.propTypes = {
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    closeModal: PropTypes.func.isRequired,
}

export default Modal
