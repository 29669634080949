import config from '../config/l10n'

export const TranslateString = (locale, slug, vars) => {
    let result = config[locale] && config[locale][slug] ? config[locale][slug] : ''
    if (vars && vars.length > 0) {
        vars.forEach((val, key) => (result = result.replace(`$${key}`, vars[key])))
    }

    return result
}

export const getMonth = (locale, month, abbreviation = false) => {
    const result = TranslateString(locale, `ui__months-${month}`)
    return abbreviation ? result.substring(0, 3) : result
}
