import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { UI, Colors } from '../../styles/StyleConf'
import { Input } from './Input'

const Autocomplete = ({ id, formik, onChange, onSelect }) => {
    const [namespace, key, key2] = id.split('.')
    const [value, setValue] = useState(key2 ? formik.values[namespace][key][key2] : formik.values[namespace][key])
    const [items, setItems] = useState([])

    const fetchData = e => {
        const value = e.target.value
        setValue(value)
        const promise = onChange(value)
        promise && promise.then(res => setItems(res))
    }

    return (
        <Wrapper>
            <Input
                onChange={fetchData}
                onClick={fetchData}
                onFocus={fetchData}
                onBlur={() => {
                    setTimeout(() => setItems(null), 300)
                }}
                value={value}
            />
            {items && items.length > 0 && (
                <Menu>
                    {items.map(item => (
                        <Row
                            key={item.id}
                            onClick={() => {
                                const shortLabel = item.label.split(',')[0]
                                setValue(shortLabel)
                                setItems([])
                                onSelect(item)
                            }}
                        >
                            {item.label}
                        </Row>
                    ))}
                </Menu>
            )}
        </Wrapper>
    )
}

const Wrapper = styled.div`
    position: relative;
    width: 100%;
`
const Menu = styled.ul`
    position: absolute;
    top: 100%;
    left: 0;
    z-index: ${UI.zMenuOption};
    background-color: ${Colors.brandWhite};
    margin: 8px 0;
    padding: 4px 0;
    border-radius: 4px;
    min-width: 100%;
    max-height: 400px;
    list-style: none;
    overflow-y: auto;
    box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1);
`
const Row = styled.li`
    &:hover,
    &:focus {
        background-color: ${Colors.brandBlueLightest};
    }

    cursor: pointer;
    padding: 8px 12px;
`

Autocomplete.propTypes = {
    id: PropTypes.string.isRequired,
    formik: PropTypes.shape({
        values: PropTypes.object.isRequired,
    }).isRequired,
    onChange: PropTypes.func.isRequired,
    onSelect: PropTypes.func.isRequired,
}

export default Autocomplete
