import React from 'react'
import styled from 'styled-components'
import Svg from './Svg'
import Config from '../../config/Config'

const OikotieLogo = () => {
    return (
        <LogoWrapper>
            <a href={Config.links.oikotieLink} target="_blank" rel="noopener noreferrer">
                <Svg type="oikotie" sizeByContainer />
            </a>
        </LogoWrapper>
    )
}

const LogoWrapper = styled.div`
    height: 60px;
    width: 200px;
    cursor: pointer;
`

export default OikotieLogo
