import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { UI, Colors, HelpIcon } from './styles/StyleConf'
import Config from './config/Config'

class Tooltip extends React.Component {
    constructor(props) {
        super(props)

        this.tootipRoot = document.getElementById(Config.ui.tooltipId)
        this.el = document.createElement('div')

        this.state = {
            visible: false,
            mouseX: null,
            mouseY: null,
        }

        this.icon = React.createRef()

        this.toggleVisibility = this.toggleVisibility.bind(this)

        this.config = {
            width: props.width ? props.width : 22,
        }
    }

    toggleVisibility(e, visible) {
        let rightOffset, mouseX, mouseY, iconPosition, overlayWidth
        if (visible) {
            rightOffset = -(e.clientX - window.innerWidth)
            overlayWidth = (window.innerWidth / 100) * this.config.width
            if (overlayWidth < 260) {
                overlayWidth = 260
            }
            mouseX =
                rightOffset < overlayWidth
                    ? e.clientX + window.pageXOffset - (overlayWidth - rightOffset + 20)
                    : e.clientX + window.pageXOffset

            iconPosition = this.icon.current.getBoundingClientRect()
            mouseY = window.pageYOffset + iconPosition.bottom
        }
        this.setState({ visible, mouseX, mouseY })
    }

    componentDidMount() {
        this.tootipRoot.appendChild(this.el)
    }

    componentWillUnmount() {
        this.tootipRoot.removeChild(this.el)
    }

    render() {
        const { children, className } = this.props
        const { visible, mouseX, mouseY } = this.state

        const OL = ReactDOM.createPortal(
            <Overlay
                visible={visible}
                onTouchStart={e => this.toggleVisibility(e, true)}
                mouse={{ x: mouseX, y: mouseY }}
                width={this.config.width}
            >
                {children}
            </Overlay>,
            this.el
        )

        return (
            <Wrapper className={className}>
                <HelpIcon
                    onMouseEnter={e => this.toggleVisibility(e, true)}
                    onMouseLeave={e => this.toggleVisibility(e, false)}
                    onTouchStart={e => this.toggleVisibility(e, true)}
                    ref={this.icon}
                />
                {OL}
            </Wrapper>
        )
    }
}

const Wrapper = styled.span`
    align-self: baseline;
`
const Overlay = styled.div`
    display: ${props => (props.visible ? 'block' : 'none')};
    z-index: ${UI.zConfirmation};
    position: absolute;
    width: ${props => `${props.width}%`};
    min-height: 40px;
    min-width: 260px;
    ${props => props.mouse.y && `top: ${props.mouse.y + 10}px`};
    ${props => props.mouse.x && `left: ${props.mouse.x}px`};
    border: 1px solid ${Colors.uiGray};
    background: ${Colors.brandWhite};
    padding: 0.6em;
    white-space: pre-wrap;
`

Tooltip.propTypes = {
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    width: PropTypes.number,
    className: PropTypes.string,
}

export default Tooltip
