import { format, isDate } from 'date-fns'

const threeDaysInMillis = 3 * 24 * 3600000

export const FormatDate = (date, type) => {
    const dateObj = new Date(date)

    let fmt
    switch (type) {
        case 'iso':
            fmt = 'yyyy-MM-dd'
            break
        case 'input':
            fmt = 'yyyy-MM-dd'
            break
        case 'D':
            fmt = 'd.M.yyyy'
            break
        case 'Y':
            fmt = 'yyyy'
            break
        case 'DT':
        default:
            fmt = 'd.M.yyyy H:mm'
            break
    }
    return date && isDate(dateObj) ? format(dateObj, fmt) : ''
}

export const ParseDate = str => {
    if (!str) return null
    const dateObj = new Date(str)
    return str && isDate(dateObj) ? dateObj : undefined
}

export const CheckThreeDaysValidity = date => {
    const now = new Date()

    const dateObj = ParseDate(date)

    if (!dateObj) return false

    if (now.getTime() < dateObj.getTime()) return true

    return false
}

export const AddThreeDays = date => {
    const dateObj = ParseDate(date)

    if (!dateObj) return undefined

    return new Date(dateObj.getTime() + threeDaysInMillis)
}
