import React from 'react'
import { withLocale } from '../../context/l10nContext'
import styled from 'styled-components'
import { sendRest } from '../../utils/APIUtils'
import { TranslateString as t } from '../../utils/DictionaryUtils'
import EditableField from '../input/EditableField'
import { Formik } from 'formik'
import Button from '../input/Button'

class ForgotPassword extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            emailResetError: false,
            isEmailSubmitting: false,
            isMailSent: false,
        }
        this.doEmail = this.doEmail.bind(this)
    }

    doEmail(values) {
        this.setState({ isEmailSubmitting: true, emailResetError: false })

        const payload = {
            pdx_user: values.email,
            pdx_name: values.pdx,
        }

        sendRest({
            path: 'account/reset_password',
            method: 'POST',
            payload: payload,
            noErrorHandling: true,
        })
            .then(res => {
                if (res.error && res.error.code >= 400) {
                    this.setState({ emailResetError: true, isEmailSubmitting: false })
                } else {
                    this.setState({ emailResetError: false, isEmailSubmitting: false, isMailSent: true })
                }
            })
            .catch(() => {
                this.setState({ emailResetError: true, isEmailSubmitting: false })
            })
    }

    render() {
        const { locale, pdx } = this.props
        const { isEmailSubmitting, emailResetError, isMailSent } = this.state

        return (
            <ForgetDiv>
                <ReadyButton
                    onClick={() => {
                        this.props.setReady()
                    }}
                >
                    {t(locale, 'login__label--did-you-forgot-password')}
                </ReadyButton>
                <FlexFormik initialValues={{ email: '', pdx: pdx }} validationSchema={null} enableReinitialize={true}>
                    {({ values, touched, errors }) => {
                        const isTouched = Object.keys(touched).length > 0
                        const hasErrors = Object.keys(errors).length > 0
                        return (
                            <FlexForm
                                onSubmit={e => {
                                    e.preventDefault()
                                    e.stopPropagation()
                                    this.doEmail(values)
                                }}
                            >
                                <EmailInputs>
                                    <ForgotLabel>{t(locale, 'ui__general--email-or-username')}</ForgotLabel>
                                    <EditableField
                                        id="email"
                                        required
                                        onChange={() => {
                                            this.setState({ emailResetError: false })
                                        }}
                                    />
                                    <ActionWrapperForget>
                                        <ForgetSubmit
                                            disabled={isEmailSubmitting || hasErrors || !isTouched || isMailSent}
                                        >
                                            {t(
                                                locale,
                                                isMailSent
                                                    ? 'login__label--sent'
                                                    : isEmailSubmitting
                                                    ? 'ui__general--processing'
                                                    : 'ui__general--send'
                                            )}
                                        </ForgetSubmit>
                                    </ActionWrapperForget>
                                </EmailInputs>
                                {(emailResetError || isMailSent) && (
                                    <InfoDiv>
                                        {emailResetError && (
                                            <InfoLabel>{t(locale, 'login__label--email-error')}</InfoLabel>
                                        )}
                                        {isMailSent && (
                                            <InfoLabel>
                                                {t(locale, 'login__label--forgot-password-email-sent')}
                                            </InfoLabel>
                                        )}
                                    </InfoDiv>
                                )}
                            </FlexForm>
                        )
                    }}
                </FlexFormik>
            </ForgetDiv>
        )
    }
}

const ForgetDiv = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`
const ReadyButton = styled.button`
    border: none;
    background: none;
    color: #4498d3;
    height: 100px;
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    font-weight: 600;
    white-space: nowrap;
`

const FlexFormik = styled(Formik)`
    display: flex;
    align-items: center;
    justify-content: end;
`

const FlexForm = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: end;
    width: 46em;
`

const EmailInputs = styled.div`
    display: flex;
    flex-direction: row;
    align-items: baseline;
`

const ForgetSubmit = styled(Button).attrs(() => ({ type: 'submit' }))`
    display: flex;
    align-content: start;
    margin-left: 1em;
`

const ForgotLabel = styled.label`
    margin-right: 1em;
    justify-content: flex-start;
    width: 109px;
    height: 19px;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #202020;
`
const ActionWrapperForget = styled.div`
    display: flex;
    align-items: center;
`

const InfoLabel = styled.div`
    width: 100%;
    align-items: center;
`

const InfoDiv = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 1em;
`

ForgotPassword.propTypes = {}

export default withLocale(ForgotPassword)
