import React, { useState } from 'react'
import PropTypes from 'prop-types'
import DatePicker, { registerLocale } from 'react-datepicker'
import fi from 'date-fns/locale/fi'
import 'react-datepicker/dist/react-datepicker.css'

registerLocale('fi', fi)

const Datepicker = ({ id, selected, setDate, className, disabled, clearable, isTimeOnly, minDate }) => {
    const [selectedTime, setSelectedTime] = useState(selected)

    const updateSelectedDate = ({ newDay, newMonth, newFullYear }) => {
        if (!selected) selected = new Date()
        if (newDay) {
            const dayValue = parseInt(newDay)
            if (dayValue > 0 && dayValue <= 31) selected.setDate(dayValue)
        }
        if (newMonth) {
            const monthValue = parseInt(newMonth) - 1
            if (monthValue >= 0 && monthValue < 12) selected.setMonth(monthValue)
        }
        if (newFullYear) {
            const yearValue = parseInt(newFullYear)
            const yearMaxValue = 2100
            if (yearValue >= 1970 && yearValue < yearMaxValue) selected.setFullYear(yearValue)
        }
        return selected
    }

    const clearEmptyDate = ({ clearable, noTargetValue }) => {
        if (clearable && noTargetValue) {
            selected = undefined
            setDate(selected)
        }
    }

    const updateDateByMouse = ({ date, event }) => {
        if (event && event.nativeEvent && event.nativeEvent.type === 'click') setDate(date)
    }

    const updateDateByKeys = event => {
        if (
            selected &&
            event.target &&
            !event.target.value &&
            (event.key === 'Enter' || event.key === 'Tab' || event.key === 'Backspace')
        ) {
            clearEmptyDate({ clearable, noTargetValue: !event.target.value })
        } else if (event.target && event.target.value && (event.key === 'Enter' || event.key === 'Tab')) {
            const dayMonthYear = event.target.value.split('.')
            const newDay = dayMonthYear[0] || event.target.value
            const newMonth = dayMonthYear[1]
            const newFullYear = dayMonthYear[2]

            if (newDay && !isNaN(newDay)) {
                selected = updateSelectedDate({ newDay, newMonth, newFullYear })
                setDate(selected)
            }
        }
    }

    return isTimeOnly ? (
        <DatePicker
            id={id}
            selected={selectedTime}
            onChange={date => {
                setSelectedTime(date)
                setDate(date)
            }}
            locale="fi"
            className={className}
            disabled={disabled}
            showTimeSelect
            showTimeSelectOnly
            timeCaption="aika"
            dateFormat="HH:mm"
            onChangeRaw={e => e.preventDefault()}
            disabledKeyboardNavigation
            autoComplete={'off'}
        />
    ) : (
        <DatePicker
            id={id}
            selected={selected}
            onKeyDown={updateDateByKeys}
            onSelect={(date, event) => updateDateByMouse({ date, event })}
            onBlur={event => clearEmptyDate({ clearable, noTargetValue: !event.target.value })}
            dateFormat="d.M.yyyy"
            locale="fi"
            className={className}
            disabled={disabled}
            disabledKeyboardNavigation
            minDate={minDate}
            autoComplete={'off'}
        />
    )
}

Datepicker.propTypes = {
    id: PropTypes.string.isRequired,
    selected: PropTypes.object,
    setDate: PropTypes.func.isRequired,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    clearable: PropTypes.bool,
    isTimeOnly: PropTypes.bool,
    minDate: PropTypes.object,
}

export default Datepicker
