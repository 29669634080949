import Config from '../config/Config'
import { toastConnectionError } from '../components/ui/Toast'

const timeout = (time, path, promise) =>
    new Promise((resolve, reject) => {
        setTimeout(() => {
            reject(new Error('Request timed out. Rest path: ' + path))
        }, time)
        promise.then(resolve, reject)
    })

const checkCredentials = () => {
    return new Promise((resolve, reject) => {
        fetchRest({ path: 'settings', noErrorHandling: true, logoutOnFail: false })
            .then(res => {
                if (String(res.status) !== 'success') {
                    reject('Unauthorized')
                } else {
                    if (res.data && res.data.pdx && validateInstallation(res.data.pdx)) resolve('Authorized')
                    else reject('Unauthorized')
                }
            })
            .catch(() => reject('Unauthorized'))
    })
}

const validateInstallation = pdx => {
    return (
        pdx.name &&
        pdx.name.toLowerCase().trim() ===
            installationFromUrl()
                .toLowerCase()
                .trim()
    )
}

const installationFromUrl = () => {
    let params = window.location.pathname.split('/'),
        pdx = ''

    if ((params[0] === '' || params[0].includes('pdx.fi')) && params[1] && params[1].trim().length > 0) {
        pdx = params[1].trim()
    }

    return pdx
}

const getLoginPath = () => {
    const pdx = installationFromUrl()
    return window.location.origin + '/' + pdx + '/login'
}

const getInstallationPath = () => {
    const pdx = installationFromUrl()
    return window.location.origin + '/' + pdx
}

const getPasswordHash = () => {
    let params = window.location.pathname.split('/')

    if (params[2] && params[2].includes('reset_password') && params[3]) {
        return params[3]
    }

    return null
}

const pdxLoginRedirect = logoutOnFail => {
    const pdx = installationFromUrl()

    if (pdx.length === 0 || window.location.href === 'https://www.pdx.fi/') {
        document.location = 'https://asunnot.oikotie.fi/pdx-constructor'
        return {}
    } else {
        if (logoutOnFail) {
            document.location = `/${pdx}/logout`
        }
        return {}
    }
}

const pdxLegacyRedirect = () => {
    const pdx = installationFromUrl()

    if (pdx.length === 0 || window.location.href === 'https://www.pdx.fi/') {
        document.location = 'https://asunnot.oikotie.fi/pdx-constructor'
        return {}
    } else {
        let url = `https://intra.pdx.fi/${pdx}/intra/index.php`
        document.location = url
        return {}
    }
}

const fetchRest = async ({
    path,
    payload,
    method = 'GET',
    params,
    defaults,
    noErrorHandling,
    customTimeout,
    logoutOnFail = true,
}) => {
    let headers = new Headers()
    headers.append('Accept', 'application/json')
    headers.append('Content-Type', 'application/json')

    defaults = Object.assign({}, { extended: 1, remove_empty: 1 }, defaults)

    let urlParams = '?'
    if (params && params.length) {
        urlParams += params.join('&')
    }
    for (let key in defaults) {
        urlParams += `&${key}=${defaults[key]}`
    }
    let url = `${Config.api.scheme}://${Config.api.domain}${Config.api.path}${path}${urlParams}`

    const request = new Request(url, {
        headers,
        body: JSON.stringify(payload),
        method,
        credentials: 'include',
    })

    const timeoutVal = customTimeout ? customTimeout : Config.api.timeout

    return await timeout(timeoutVal, path, fetch(request))
        .then(res => {
            if (res.status && res.status === 401) {
                return pdxLoginRedirect(logoutOnFail)
            } else {
                return res.json()
            }
        })
        .catch(err => !noErrorHandling && toastConnectionError(err))
}

const servicePrint = ({ payload, callback, errCallback }) => {
    fetchRest({
        path: 'services/print',
        payload,
        method: 'POST',
    })
        .then(res => res.data)
        .then(data => {
            if (callback) callback(data)
        })
        .catch(err => {
            toastConnectionError(err)
            if (errCallback) errCallback()
        })
}

const queryVipunen = async ({ path, payload, method = 'GET', params }) => {
    let headers = new Headers()
    headers.append('Accept', '*/*')

    let urlParams = ''
    if (params && params.length) {
        urlParams += '?'
        urlParams += params.join('&')
    }

    let url = `${Config.vipunen.scheme}://${Config.vipunen.domain}/${path}${urlParams}`

    const body = new FormData()
    body.append('address', payload.address)
    const request = new Request(url, {
        headers,
        body,
        method,
    })

    return await timeout(Config.api.timeout, path, fetch(request))
        .then(res => {
            return res.json()
        })
        .catch(err => toastConnectionError(err))
}

const sendRest = async ({ path, payload, method = 'POST', formdata, contentType, timeoutOverride }) => {
    let headers = new Headers()
    headers.append('Accept', '*/*')

    const url = `${Config.api.scheme}://${Config.api.domain}${Config.api.path}${path}`

    let body
    if (formdata) {
        body = formdata
        if (contentType) headers.append('Content-Type', contentType)
    } else {
        headers.append('Content-Type', contentType || 'application/json')
        body = JSON.stringify(payload)
    }
    const request = new Request(url, {
        headers,
        body: body,
        method,
        credentials: 'include',
    })

    return await timeout(timeoutOverride || Config.api.timeout, path, fetch(request)).then(res => res.json())
}

const fetchKeyValuePairs = ({ storagePath, identifier }) => {
    return fetchRest({
        path: 'storage',
        params: [`path=${storagePath}`, `identifier=${identifier}`],
        defaults: {
            remove_empty: 0,
        },
    })
}

const storeKeyValuePairs = ({ data, path, identifier }) => {
    return fetchRest({
        path: 'storage',
        payload: { data, path, identifier },
        method: 'POST',
        defaults: { remove_empty: 0 },
    }).catch(err => toastConnectionError(err))
}

const parseJsonString = ({ data }) => {
    if (!data) return {}

    if (typeof data === 'string') {
        return JSON.parse(data)
    }

    return data
}

export {
    servicePrint,
    checkCredentials,
    fetchRest,
    queryVipunen,
    sendRest,
    pdxLoginRedirect,
    pdxLegacyRedirect,
    validateInstallation,
    fetchKeyValuePairs,
    storeKeyValuePairs,
    parseJsonString,
    installationFromUrl,
    getLoginPath,
    getInstallationPath,
    getPasswordHash,
}
