import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import debounce from 'lodash/debounce'
import Header from './Header'
import { Colors, UI, Fonts } from '../../styles/StyleConf'
import { UIContext } from '../../context/UIContext'
import Modal from '../../Modal'
import { withRouter } from 'react-router-dom'
import { withSettings } from '../../context/SettingsContext'

const Wrapper = styled.div`
    font-family: ${Fonts.brandFont};
    ${({ plain }) =>
        !plain &&
        `
            width: 100%;
            background-color: ${Colors.bgGray};
            min-width: 320px;
        `}
    width: 100%;
    background-color: ${Colors.bgGray};
    min-width: 320px;
`
const Content = styled.div`
    ${({ plain }) =>
        !plain &&
        `
            width: 100%;
            max-width: ${UI.contentWidth}px;
            min-height: calc(100vh - ${UI.headerHeight}px) - 20px;
            min-width: 320px;
            margin: 20px auto 0;
        `}
    width: 100%;
    max-width: ${UI.contentWidth}px;
    min-height: calc(100vh - ${UI.headerHeight}px) - 20px;
    min-width: 320px;
    margin: 20px auto 40px;
    padding: 0;
`
class SiteWrapper extends React.Component {
    constructor(props) {
        super(props)

        this.toggleModal = ({ modal }) => {
            this.setState({ modal })
        }
        this.toggleUserMenu = forceOff => {
            const userMenu = forceOff ? false : !this.state.userMenu
            this.setState({ userMenu, mobileNavi: false })
        }
        this.toggleMobileNavi = forceOff => {
            const mobileNavi = forceOff ? false : !this.state.mobileNavi
            this.setState({ mobileNavi, userMenu: false, activeNavi: null })
        }
        this.toggleActiveNavi = activeNavi => {
            if (activeNavi === this.state.activeNavi) return
            this.setState({ activeNavi })
        }

        this.state = {
            pathname: this.props.history.location.pathname,
            modal: null,
            toggleModal: this.toggleModal,
            userMenu: false,
            toggleUserMenu: this.toggleUserMenu,
            mobileNavi: false,
            toggleMobileNavi: this.toggleMobileNavi,
            root: this.props.root,
            activeNavi: null,
            toggleActiveNavi: debounce(this.toggleActiveNavi, 300),
        }

        this.toggleModal = this.toggleModal.bind(this)
    }

    componentDidUpdate() {
        if (this.state.pathname !== this.props.history.location.pathname) {
            this.setState({ pathname: this.props.history.location.pathname })
            window.scrollTo(0, 0)
        }
    }

    render() {
        const { children, stripped, settings, ...rest } = this.props
        const { modal } = this.state

        return (
            <Wrapper plain={stripped} {...rest}>
                <UIContext.Provider value={this.state} key="uc">
                    {!stripped && <Header settings={settings} />}
                    <Content plain={stripped}>{children}</Content>
                    {modal && <Modal closeModal={this.toggleModal}>{modal}</Modal>}
                </UIContext.Provider>
            </Wrapper>
        )
    }
}

SiteWrapper.propTypes = {
    history: PropTypes.shape({
        location: PropTypes.shape({
            pathname: PropTypes.string,
        }).isRequired,
    }).isRequired,
    children: PropTypes.object.isRequired,
    stripped: PropTypes.bool,
    root: PropTypes.string,
    settings: PropTypes.shape({
        user: PropTypes.shape({}),
        username: PropTypes.string,
        pdx: PropTypes.shape({
            name: PropTypes.string,
        }),
    }),
}

export default withRouter(withSettings(SiteWrapper))
