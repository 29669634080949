import { keyframes } from 'styled-components'

export const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
`

export const fadeInOverlay = keyframes`
    from {
        opacity: 1;
    }
    to {
        opacity: 0.5;
    }
`

export const controlBarAppear = keyframes`
    from {
        bottom: -100px;
    }
    to {
        bottom: 0px;
    }
`

export const dipIn = keyframes`
    0% {
        opacity: 0;
    }
    20% {
        opacity: 1;
    }
    80% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
`
