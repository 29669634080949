import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Colors, UI } from '../../styles/StyleConf'
import Logo from '../ui/Logo'

const Footer = noTopMargin => {
    return (
        <Wrapper noTopMargin={noTopMargin}>
            <Inner>
                <StyledLogo type="inverse" />
            </Inner>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    background-color: ${Colors.brandBlack};
    margin-top: ${props => (props.noTopMargin ? '0px' : '20px')};
    padding: 24px 0 0;
    height: ${UI.footerHeight}px;
`
const Inner = styled.div`
    width: ${UI.contentWidth}px;
    max-width: 100%;
    margin: 0 auto;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
`
const StyledLogo = styled(Logo)`
    margin-right: 40px;
`

Footer.propTypes = {
    noTopMargin: PropTypes.bool,
}

export default Footer
