import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { ifProp } from 'styled-tools'
import { Buttons, Colors, Fonts, TruncText } from '../../styles/StyleConf'

const Button = ({ kind, size, children, ...rest }) => {
    const commonStyles = css`
        height: ${size === 'small' ? Buttons.height.small : Buttons.height.medium};
        font-family: ${Fonts.actionFont};
        font-size: ${size === 'small' ? Fonts.small : Fonts.action};
        font-weight: bold;
        padding: 0 ${size === 'small' ? '10' : '20'}px;
        border-radius: 3px;
        display: flex;
        align-items: center;
    `
    let baseStyles
    switch (kind) {
        case 'legacy':
            baseStyles = css`
                background-color: ${Colors.brandOrange};
                border: 1px solid ${Colors.darkGray};
                font-size: ${Fonts.small};
            `
            break
        case 'secondary':
            baseStyles = css`
                ${commonStyles};
                background-color: ${Colors.brandWhite};
                border: 1px solid ${Colors.uiGray};
                color: ${Colors.brandBlue};
            `
            break
        case 'anchor':
            baseStyles = css`
                ${commonStyles};
                background: transparent;
                border: 0;
                color: ${Colors.brandBlue};
                padding: 0;
                height: auto;
            `
            break
        case 'destructive':
            baseStyles = css`
                ${commonStyles};
                background-color: ${Colors.errorRed};
                border: 1px solid ${Colors.errorRed};
            `
            break
        case 'link':
            baseStyles = css`
                background-color: transparent;
                border: 0;
            `
            break
        default:
            baseStyles = css`
                ${commonStyles};
                background-color: ${Colors.actionBlue};
                border: 1px solid ${Colors.actionBlue};
            `
    }

    return (
        <StyledButton {...rest} baseStyles={baseStyles}>
            {children}
        </StyledButton>
    )
}

Button.propTypes = {
    kind: PropTypes.string,
    size: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array, PropTypes.number]),
    active: PropTypes.bool,
    disabled: PropTypes.bool,
}

const StyledButton = styled.button`
    &:active {
        opacity: 0.7;
    }

    &:focus {
        outline-width: 2px;
        outline-style: solid;
    }

    user-select: none;
    color: white;
    opacity: ${props => (props.disabled || props.active ? '0.5' : '1')};
    cursor: ${ifProp('disabled', 'default', 'pointer')};
    max-width: 20em;
    white-space: ${ifProp('multiline', 'wrap', 'nowrap')};
    ${TruncText};
    ${props => props.baseStyles};
`

StyledButton.propTypes = {
    active: PropTypes.bool,
    disabled: PropTypes.bool,
    multiline: PropTypes.bool,
    baseStyles: PropTypes.array.isRequired,
}

export default Button
