import styled from 'styled-components'
import { ifProp } from 'styled-tools'
import { Colors, Fonts } from './StyleConf'

export const H1 = styled.h1`
    font-size: ${Fonts.large};
    margin: 0 0 1em;
    text-transform: uppercase;
`

export const H2 = styled.h2`
    ${props =>
        props.legacy
            ? `
    display: block;
    background-color: ${Colors.brandBlueLight};
    font-size: ${Fonts.small};
    color: white;
    padding: 2px 6px;
    border: 1px solid ${Colors.darkGray};
`
            : `
    font-size: ${Fonts.medium};
    text-transform: uppercase;
`};
    white-space: nowrap;
    margin: 0 0 1em;
`

export const H3 = styled(H2)`
    font-size: ${Fonts.small};
`

export const Label = styled.label`
    ${props =>
        props.req
            ? `
        &:after {
            content: '*';
            margin-left: .3em;
            color: ${Colors.errorRed};
        }
    `
            : undefined};
    font-size: ${Fonts.small};
    user-select: none;
    cursor: ${ifProp('disabled', 'default', 'pointer')};
`

export const Timestamp = styled.span`
    color: ${Colors.veryDarkGrey};
    font-size: ${Fonts.small};
`
