import React from 'react'

export const SettingsContext = React.createContext({
    settings: null,
    username: null,
})

export const withSettings = Component => {
    return function ComponentSettings(props) {
        return <SettingsContext.Consumer>{settings => <Component {...props} {...settings} />}</SettingsContext.Consumer>
    }
}
