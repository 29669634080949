import React from 'react'
import { withLocale } from '../../context/l10nContext'
import styled from 'styled-components'
import { LoadingSpinner } from '../ui/LoadingSpinner'
import { fetchRest, getLoginPath } from '../../utils/APIUtils'
import { TranslateString as t } from '../../utils/DictionaryUtils'

class PasswordReset extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isError: false,
            isSending: true,
            isReady: false,
        }
        this.doRest()
    }

    doRest() {
        const payload = {
            hash: this.props.hash,
        }

        fetchRest({
            path: 'account/reset_password',
            method: 'POST',
            payload: payload,
            noErrorHandling: true,
            logoutOnFail: false,
        })
            .then(res => {
                if (res.error && res.error.code >= 400) {
                    this.setState({ isError: true, isSending: false, isReady: false })
                } else {
                    this.setState({ isError: false, isSending: false, isReady: true })
                }
            })
            .catch(() => {
                this.setState({ isError: true, isSending: false, isReady: false })
            })
    }

    render() {
        const { isError, isReady, isSending } = this.state
        const { locale, hash } = this.props
        const pdxLogin = getLoginPath()

        return (
            <Infos>
                {isSending && <LoadingSpinner boxSize={100} />}
                {isError && (
                    <div>
                        {t(locale, 'login__label--hash-error')} {hash}
                    </div>
                )}
                {isReady && (
                    <div>
                        <div>{t(locale, 'login__label--new-password-sent')}</div>
                        <div>
                            <a href={pdxLogin}>{t(locale, 'login__label--login-to-pdx')}</a>
                        </div>
                    </div>
                )}
            </Infos>
        )
    }
}

const Infos = styled.div`
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
    margin-top: 5em;
    margin-right: 10%;
    width: 100%;
    align-items: center;
`

PasswordReset.propTypes = {}

export default withLocale(PasswordReset)
