import React from 'react'
import PropTypes from 'prop-types'
import Loadable from 'react-loadable'
import ComponentLoader from './ComponentLoader'

function LoaderWrapper(path) {
    return Loadable({
        loading: ComponentLoader,
        loader: path,
        render(loaded, props) {
            const Component = loaded.default
            return <Component {...props} {...props.match.params} />
        },
    })
}

LoaderWrapper.propTypes = {
    path: PropTypes.string.isRequired,
    match: PropTypes.shape({
        params: PropTypes.object,
    }).isRequired,
}

export default LoaderWrapper
