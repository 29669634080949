import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Field, FieldArray, connect as withFormik } from 'formik'
import omit from 'lodash/omit'
import head from 'lodash/head'
import isEqual from 'lodash/isEqual'
import InputMask from 'react-number-format'
import Autocomplete from '../input/Autocomplete'
import { TranslateString as t } from '../../utils/DictionaryUtils'
import { withLocale } from '../../context/l10nContext'
import { UI, Colors, Fonts } from '../../styles/StyleConf'
import Button from './Button'
import Select from './Select'
import { TextArea } from './Input'
import { FormatDate, ParseDate } from '../../utils/DateUtils'
import Svg from '../ui/Svg'
import Datepicker from './Datepicker'
import Tooltip from '../../Tooltip'
import CharCounter from './CharCounter'
import { DecodeNumber } from '../../utils/ConversionUtils'
import FilesInput from './FilesInput'
import { RequiredTag } from './Input'
import Config from '../../config/Config'

const TaComponent = ({ field, ...props }) => (
    <TextArea {...omit(field, ['value'])} value={field.value || ''} {...omit(props, ['form', 'type'])} />
)

TaComponent.propTypes = {
    field: PropTypes.object.isRequired,
}

class EditableField extends React.Component {
    constructor(props) {
        super(props)
        const [namespace, key, key2] = this.props.id.split('.')

        this.namespace = namespace
        this.key = key
        this.key2 = key2
        this.noFormikForm = props.noFormikForm

        this.state = {
            focused: false,
            maskedValue: null,
        }

        this.setMaskedValue = this.setMaskedValue.bind(this)
        this.InputComponent = this.InputComponent.bind(this)
    }

    setMaskedValue({ props, value }) {
        let maskedValue = value

        if (props) {
            if (props.value) {
                maskedValue = props.value
            }

            if (
                this.key2 &&
                props.formik.values &&
                props.formik.values[this.namespace] &&
                props.formik.values[this.namespace][this.key] &&
                props.formik.values[this.namespace][this.key][this.key2]
            ) {
                maskedValue = props.formik.values[this.namespace][this.key][this.key2]
            } else if (
                props.formik.values &&
                props.formik.values[this.namespace] &&
                props.formik.values[this.namespace][this.key]
            ) {
                maskedValue = props.formik.values[this.namespace][this.key]
            }
        }

        this.setState({ maskedValue })
    }

    InputComponent({ field, ...props }) {
        const fields = omit(field, ['value'])
        let defaultValue = props.value || field.value || this.props.value || ''
        let parsedValue
        if (this.props.type2 && this.props.type2 === 'defaultZero' && defaultValue === '') {
            defaultValue = 0
            parsedValue = 0
        }

        let mask = false
        let separator = false
        let valueIsInteger = false

        switch (props.type) {
            case 'percentage':
            case 'currency':
            case 'area':
                mask = true
                separator = props.type !== 'percentage'
                if (this.props.calculated) {
                    parsedValue = this.props.value
                } else if (this.state.maskedValue === null) {
                    parsedValue = defaultValue
                } else {
                    parsedValue = this.state.maskedValue
                }
                break
            case 'integer':
                valueIsInteger = true
                parsedValue = defaultValue
                break
            default:
                parsedValue = defaultValue
                break
        }

        const decimals = (input, type) => {
            if (type === 'currency') return 2
            const split = String(input).split('.')
            return split && split.length > 1 ? (parseInt(split[1], 10) ? split[1].length : 0) : 0
        }

        return mask ? (
            <InputMask
                {...fields}
                value={parsedValue}
                {...omit(props, ['form', 'type'])}
                defaultValue={defaultValue}
                isNumericString={true}
                thousandSeparator={separator ? ' ' : ''}
                decimalSeparator=","
                decimalScale={this.state.focused ? 2 : decimals(parsedValue, this.props.type)}
                maxLength={props.maxLength}
                onValueChange={values => {
                    const value = values.value || ''
                    this.setMaskedValue({ value })
                    this.props.formik.setFieldValue(this.props.id, DecodeNumber(value))
                    if (!this.noFormikForm) this.props.formik.setFieldTouched(this.props.id, true)
                    if (this.props.onChange) this.props.onChange(value)
                }}
                onChange={() => null}
                onFocus={() => this.setState({ focused: true })}
                onBlur={() => {
                    if (this.props.onBlur) this.props.onBlur()
                    this.setState({ focused: false })
                }}
            />
        ) : (
            <input
                {...fields}
                value={parsedValue}
                {...omit(props, 'form')}
                maxLength={props.maxLength}
                onChange={e => {
                    const val = valueIsInteger ? (e.target.value ? parseInt(e.target.value) : '') : e.target.value
                    this.props.formik.setFieldValue(this.props.id, val)
                    if (!this.noFormikForm) this.props.formik.setFieldTouched(this.props.id, true)
                    if (this.props.onChange) this.props.onChange(val)
                }}
            />
        )
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setMaskedValue({ props: nextProps })
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (
            (this.key2 &&
                nextProps.formik.values[this.namespace] &&
                this.props.formik.values[this.namespace] &&
                nextProps.formik.values[this.namespace][this.key] &&
                this.props.formik.values[this.namespace][this.key] &&
                nextProps.formik.values[this.namespace][this.key][this.key2] !==
                    this.props.formik.values[this.namespace][this.key][this.key2]) ||
            (nextProps.formik.values[this.namespace] &&
                this.props.formik.values[this.namespace] &&
                nextProps.formik.values[this.namespace][this.key] !==
                    this.props.formik.values[this.namespace][this.key])
        )
            return true
        const nextError = this.key2
            ? nextProps.formik.errors &&
              nextProps.formik.errors[this.namespace] &&
              nextProps.formik.errors[this.namespace][this.key] &&
              nextProps.formik.errors[this.namespace][this.key][this.key2]
            : nextProps.formik.errors &&
              nextProps.formik.errors[this.namespace] &&
              nextProps.formik.errors[this.namespace][this.key]
        const thisError = this.key2
            ? this.props.formik.errors &&
              this.props.formik.errors[this.namespace] &&
              this.props.formik.errors[this.namespace][this.key] &&
              this.props.formik.errors[this.namespace][this.key][this.key2]
            : this.props.formik.errors &&
              this.props.formik.errors[this.namespace] &&
              this.props.formik.errors[this.namespace][this.key]
        if (!isEqual(nextProps.options, this.props.options)) {
            return true
        }
        if (!isEqual(nextProps.asyncOptions, this.props.asyncOptions)) {
            return true
        }
        if (!isEqual(nextProps.selected, this.props.selected)) return true
        if (nextError !== thisError) return true
        if (nextProps.calculated) return true
        if (nextProps.disabled !== this.props.disabled) return true
        if (nextProps.index !== this.props.index) return true
        if (nextState.focused !== this.state.focused) return true
        if (nextProps.isLocked !== this.props.isLocked) return true
        return false
    }

    checkForLongErrorMessages({ errors }) {
        let longErrors = false

        Object.keys(errors).forEach(namespace => {
            Object.keys(errors[namespace]).forEach(key => {
                if (errors[namespace][key].length > Config.constants.integerConst.LONG_ERROR_MESSAGE_LIMIT)
                    longErrors = true
            })
        })
        return longErrors
    }

    render() {
        const {
            locale,
            id,
            label,
            labelValues,
            disabled,
            type,
            value,
            text,
            inactive,
            suffix,
            tooltip,
            formik,
            options,
            defaultOptions,
            asyncOptions,
            searchable,
            clearable,
            multiselect,
            onChange,
            onClick,
            onSelect,
            onFocus,
            onBlur,
            prefixField,
            suffixField,
            placeholder,
            inlineCss,
            textLimit,
            extraLink,
            selected,
            required,
            isLocked,
            validateWithoutTouch,
            isTimeOnly,
            minDate,
            noFormikForm,
            callback,
        } = this.props

        const { values, errors, touched, validationSchema, setFieldValue, setFieldTouched } = formik

        const [namespace, key, key2] = id.split('.')

        let Component
        switch (type) {
            case 'button': {
                Component = (
                    <Button
                        type="button"
                        onClick={onClick}
                        disabled={disabled || isLocked}
                        style={inlineCss ? inlineCss : undefined}
                    >
                        {t(locale, text)}
                    </Button>
                )
                break
            }
            case 'select': {
                const setValue = val => {
                    const value = val ? val.value : ''
                    setFieldValue(id, value)
                    if (value !== Config.constants.stringConst.SELECT_ID_CREATE_NEW) setFieldTouched(id, true)
                    if (onChange) onChange(value)
                }

                const comparisonValue = key2
                    ? (values[namespace] && values[namespace][key] && values[namespace][key][key2]) || value
                    : (values[namespace] && values[namespace][key]) || value

                let defaultValue = comparisonValue ? head(options.filter(n => n.value === comparisonValue)) : null

                if (!defaultValue && !selected) {
                    if (typeof comparisonValue === 'string') {
                        defaultValue = options.find(n => n.label === comparisonValue)
                    } else if (options[0] && options[0].options) {
                        options.forEach(option => {
                            if (option.options) {
                                const match = option.options.find(n => n.value === comparisonValue)
                                if (match) defaultValue = match
                            }
                        })
                    }
                }

                Component = (
                    <StyledSelect
                        id={id}
                        name={id}
                        defaultValue={selected ? selected : defaultValue}
                        defaultOptions={defaultOptions}
                        options={options}
                        isSearchable={searchable}
                        isClearable={clearable}
                        onChange={setValue}
                        isMulti={multiselect}
                        isDisabled={disabled || isLocked}
                        loadOptions={asyncOptions}
                        placeholder={asyncOptions ? t(locale, placeholder) || ' ' : t(locale, placeholder) || null}
                    />
                )
                break
            }
            case 'radio':
            case 'checkbox': {
                Component = (
                    <StyledFieldArray
                        name={id}
                        render={() =>
                            options.map(option => {
                                const oc =
                                    type === 'radio'
                                        ? e => {
                                              setFieldValue(id, e.target.value)
                                              setFieldTouched(id, true)
                                              onChange && onChange(e)
                                          }
                                        : e => {
                                              setFieldValue(id, e.target.checked ? '1' : '0')
                                              setFieldTouched(id, true)
                                              onChange && onChange(e)
                                          }

                                let checked = false

                                if (namespace !== 'testing' && key !== 'testId')
                                    // TODO: find a better way to test this
                                    checked =
                                        values[namespace] && key2
                                            ? String(values[namespace][key][key2]).includes(option.value)
                                            : String(values[namespace][key]).includes(option.value)

                                return (
                                    <Wrapper key={`wrapper-${option.id}-${value}`}>
                                        <input
                                            id={`${id}-${option.value}`}
                                            name={id}
                                            type={type}
                                            value={option.value || ''}
                                            checked={checked}
                                            onChange={oc}
                                            disabled={disabled || isLocked}
                                            key={`${option.id}-${value}`}
                                        />
                                        <label htmlFor={`${id}-${option.value}`}>
                                            {option.translatedLabel || t(locale, option.label, option.labelValues)}
                                            {option.tooltip && <Tooltip>{t(locale, option.tooltip)}</Tooltip>}
                                        </label>
                                    </Wrapper>
                                )
                            })
                        }
                    />
                )
                break
            }
            case 'date': {
                const Dtpckr = ({ field, ...props }) => (
                    <Datepicker
                        id={id}
                        selected={ParseDate(field.value)}
                        setDate={date => {
                            const value = date ? (isTimeOnly ? new Date(date) : FormatDate(date, 'iso')) : ''
                            setFieldValue(id, value)
                            if (!noFormikForm) setFieldTouched(id, true)
                            if (onChange) onChange(value)
                        }}
                        clearable={clearable}
                        isTimeOnly={isTimeOnly}
                        minDate={minDate}
                        {...field}
                        {...omit(props, 'form')}
                    />
                )
                Component = (
                    <>
                        <StyledField
                            id={id}
                            name={id}
                            disabled={disabled || isLocked}
                            type={type}
                            component={Dtpckr}
                            hasSuffix={!!suffix}
                        />
                        {!isTimeOnly && <Calendar disabled={disabled || isLocked} />}
                    </>
                )
                break
            }
            case 'textarea': {
                Component = (
                    <StyledField
                        id={id}
                        name={id}
                        disabled={disabled || isLocked}
                        component={TaComponent}
                        maxLength={textLimit}
                        onChange={e => {
                            const val = e.target.value
                            setFieldValue(id, val)
                            if (!noFormikForm) setFieldTouched(id, true)
                            if (onChange) onChange(val)
                        }}
                        onFocus={() => onFocus(id)}
                        onBlur={e => {
                            onFocus(null)
                            if (onBlur) {
                                const val = e.target.value
                                if (!noFormikForm) setFieldTouched(id, true)
                                onBlur(val)
                            }
                        }}
                        extraLink={extraLink}
                    />
                )
                break
            }
            case 'text-div': {
                Component = <div id={id}>{value}</div>
                break
            }
            case 'hidden': {
                Component = <input id={id} name={id} type={type} formik={formik} />
                break
            }
            case 'file': {
                Component = <FilesInput formik={formik} id={id} callback={callback} />
                break
            }
            case 'image': {
                Component = <FilesInput formik={formik} id={id} callback={callback} accept="image/*" hideBorder />
                break
            }
            case 'typeahead': {
                Component = <Autocomplete onChange={onChange} onSelect={onSelect} id={id} formik={formik} />
                break
            }
            default: {
                Component = (
                    <StyledField
                        id={id}
                        name={id}
                        disabled={disabled || isLocked}
                        type={type}
                        component={this.InputComponent}
                        placeholder={t(locale, placeholder)}
                        hasSuffix={!!suffix}
                        onClick={onClick}
                        onBlur={onBlur}
                        maxLength={textLimit}
                    />
                )
            }
        }

        const labelTxt = t(locale, label, labelValues)
        const hasError = key2
            ? errors && errors[namespace] && errors[namespace][key] && errors[namespace][key].hasOwnProperty(key2)
            : errors && errors[namespace] && errors[namespace].hasOwnProperty(key)
        const isTouched = key2
            ? touched && touched[namespace] && touched[namespace][key] && touched[namespace][key].hasOwnProperty(key2)
            : touched && touched[namespace] && touched[namespace].hasOwnProperty(key)
        const isRequired = key2
            ? validationSchema &&
              validationSchema.fields[namespace] &&
              validationSchema.fields[namespace].fields &&
              validationSchema.fields[namespace].fields[key] &&
              validationSchema.fields[namespace].fields[key].fields[key2] &&
              validationSchema.fields[namespace].fields[key].fields[key2].tests &&
              validationSchema.fields[namespace].fields[key].fields[key2].tests.filter(
                  test => test.OPTIONS.name === 'required'
              ).length > 0
            : validationSchema &&
              validationSchema.fields[namespace] &&
              validationSchema.fields[namespace].fields &&
              validationSchema.fields[namespace].fields[key] &&
              validationSchema.fields[namespace].fields[key].tests &&
              validationSchema.fields[namespace].fields[key].tests &&
              validationSchema.fields[namespace].fields[key].tests.filter(test => test.OPTIONS.name === 'required')
                  .length > 0
        const componentValue = key2
            ? values[namespace] && values[namespace][key] && values[namespace][key][key2]
            : values[namespace] && values[namespace][key]
        const displayError = !disabled && (validateWithoutTouch ? hasError : hasError && isTouched)

        let longErrors = false

        if (Object.keys(errors).length !== 0) {
            longErrors = this.checkForLongErrorMessages({ errors })
        }

        return (
            <>
                {label && (
                    <Label
                        htmlFor={id}
                        disabled={disabled && inactive}
                        key={`label-${id +
                            JSON.stringify(errors.hasOwnProperty(namespace) && errors[namespace].hasOwnProperty(key))}`}
                    >
                        {labelTxt}
                        {(isRequired || required) && <RequiredTag>*</RequiredTag>}
                        {tooltip && <Tooltip>{t(locale, tooltip)}</Tooltip>}
                    </Label>
                )}
                <InputWrapper
                    halfSize={prefixField || suffixField}
                    suffix={suffix}
                    suffixField={suffixField}
                    inlineCss={inlineCss}
                    key={`value-${id + value + JSON.stringify(disabled) + suffix + String(value).length}`}
                    hasError={displayError}
                >
                    {(textLimit || extraLink) && (
                        <ActionWrapper>
                            {extraLink && extraLink}
                            {textLimit && (
                                <CharCounter count={componentValue ? componentValue.length : 0} limit={textLimit} />
                            )}
                        </ActionWrapper>
                    )}
                    {Component}
                    {suffix && <Suffix outline={['select'].includes(type)}>{suffix}</Suffix>}
                    {displayError && <ErrorContainer longErrors={longErrors}>{errors[namespace][key]}</ErrorContainer>}
                </InputWrapper>
            </>
        )
    }
}

const InputWrapper = styled.div`
    display: flex;
    align-items: center;
    position: relative;
    ${({ inlineCss }) => inlineCss};

    select {
        background-color: transparent;
    }

    ${UI.togglers};

    .react-datepicker-wrapper {
        width: 100%;
    }

    .react-datepicker__input-container {
        display: block;
        width: 106%;
    }

    ${({ hasError }) =>
        hasError &&
        `
            input,
            textarea,
            .react-select__control {
                border-color: ${Colors.errorRed};
            }
        `}
    ${props =>
        props.halfSize &&
        `
        display: inline-flex;
        width: 50%;
        padding-left: ${props.suffixField ? '16px' : '0'};

        input {
            max-width: 100%;
        }
    `}
`
const StyledFieldArray = styled(FieldArray)`
    width: 100%;
`
const StyledSelect = styled(Select)`
    min-width: 12em;
    width: 100%;
`
const StyledField = styled(({ ...props }) => <Field {...omit(props, ['hasSuffix'])} />)`
    &:disabled {
        background-color: ${Colors.bgGray};
    }

    border: 1px solid ${Colors.uiGray};
    width: 100%;
    height: 45px;
    border-radius: 2px;
    font-size: ${Fonts.medium};
    padding: ${({ hasSuffix }) => `${hasSuffix ? '0 2em ' : ''}0 0.6em`};
    cursor: inherit;

    ${UI.inputShadow};
    ${({ type }) => type === 'file' && 'padding-top: 12px;'}
`
const Label = styled.label`
    display: block;
    ${UI.labelStyles};
    padding-bottom: 6px;
    user-select: none;
`
const ErrorContainer = styled.div`
    ${UI.inputError};
    cursor: pointer;
    ${({ longErrors }) => longErrors && 'margin-top: 70px'};
`
const Suffix = styled.span`
    ${UI.labelFont};
    text-transform: lowercase;
    pointer-events: none;
    position: absolute;
    right: ${({ outline }) => (outline ? '3.4em' : '1em')};
`
const Calendar = styled(Svg).attrs({ type: 'calendar' })`
    position: relative;
    right: 14px;
    pointer-events: none;
    fill: ${({ disabled }) => (disabled ? Colors.uiGray : Colors.brandBlue)};
`
const Wrapper = styled.div`
    margin-top: 6px;
`

const ActionWrapper = styled.div`
    position: absolute;
    top: -27px;
    right: 0;
`

EditableField.defaultProps = {
    searchable: true,
    multiselect: false,
    options: [],
    isLocked: false,
}

EditableField.propTypes = {
    locale: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    label: PropTypes.string,
    disabled: PropTypes.bool,
    calculated: PropTypes.bool,
    index: PropTypes.string,
    inactive: PropTypes.bool,
    type: PropTypes.string,
    type2: PropTypes.string,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            label: PropTypes.string,
        })
    ),
    defaultOptions: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            label: PropTypes.string,
        })
    ),
    asyncOptions: PropTypes.func,
    suffix: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
        PropTypes.shape({
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            label: PropTypes.string,
        }),
        PropTypes.object,
    ]),
    labelValues: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
        PropTypes.shape({
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            label: PropTypes.string,
        }),
        PropTypes.object,
    ]),
    text: PropTypes.string,
    searchable: PropTypes.bool,
    clearable: PropTypes.bool,
    multiselect: PropTypes.bool,
    onChange: PropTypes.func,
    onSelect: PropTypes.func,
    onClick: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    prefixField: PropTypes.bool,
    suffixField: PropTypes.bool,
    tooltip: PropTypes.string,
    formik: PropTypes.object,
    errors: PropTypes.object,
    placeholder: PropTypes.string,
    inlineCss: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    textLimit: PropTypes.number,
    extraLink: PropTypes.object,
    selected: PropTypes.shape(),
    required: PropTypes.bool,
    isLocked: PropTypes.bool,
    validateWithoutTouch: PropTypes.bool,
    isTimeOnly: PropTypes.bool,
    minDate: PropTypes.object,
    noFormikForm: PropTypes.bool,
    callback: PropTypes.func,
}

export default withFormik(withLocale(EditableField))
