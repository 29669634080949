import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Colors, UI, Fonts } from '../../styles/StyleConf'
import Svg from '../ui/Svg'
import Config from '../../config/Config'
import Button from '../input/Button'
import { hexToRGB } from '../../utils/ConversionUtils'

const brandBlueRGB = hexToRGB(Colors.brandBlue).join(',')

const Wrapper = styled.div`
    z-index: ${UI.zMobileNavi};
`
const Navi = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;
    z-index: ${UI.zMobileNavi};

    li {
        &:nth-of-type(even) {
            a,
            span {
                background-color: rgba(${brandBlueRGB}, 0.8);
            }
        }

        &.active {
            a,
            span {
                color: ${Colors.brandBlack};
                font-weight: bold;
            }
        }

        &.navigation__main--subitem {
            &:first-of-type {
                padding-left: 1em;
            }
            &:hover {
                a,
                span {
                    ${Fonts.linkHover};
                }
            }

            background: ${Colors.brandBlueLightest};
            border: 1px solid ${Colors.uiGray};
            border-top: 0;
        }

        display: block;
        text-transform: uppercase;

        & > a,
        & > span {
            &:hover,
            &:active {
                ${Fonts.linkHover};
                background-color: rgba(${brandBlueRGB}, 0.5);
            }

            display: block;
            padding: 16px 20px;
            color: ${Colors.brandWhite};
            text-transform: uppercase;
            text-decoration: none;
            background-color: ${Colors.brandBlue};
        }
    }

    position: fixed;
    top: ${UI.headerHeightMobile}px;
    right: 0;
    left: 0;
    background-color: ${Colors.brandWhite};
`
const ToggleBtn = styled(Button)`
    && {
        &:active {
            outline: none;
        }

        border: none;
        background: transparent;
        padding: 0 1em;
    }
`
const Hamburger = styled(Svg).attrs({ type: 'menu' })`
    min-width: 16px;

    @media (min-width: ${Config.breakpoints.mobile}px) {
        transform: scale(1.4);
    }
`
const CloseIcon = styled(Svg).attrs({ type: 'close' })``
const Seranwrap = styled.div`
    position: fixed;
    top: ${UI.headerHeightMobile}px;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: ${UI.zMobileNavi - 1};
    background-color: ${Colors.brandWhite};
    opacity: 0.4;
`

const MobileNavi = ({ children, className, active, toggleMenu }) => (
    <Wrapper className={className}>
        <ToggleBtn onClick={toggleMenu}>{active ? <CloseIcon /> : <Hamburger />}</ToggleBtn>
        {active && <Seranwrap onClick={toggleMenu} />}
        {active && <Navi>{children}</Navi>}
    </Wrapper>
)

MobileNavi.propTypes = {
    children: PropTypes.array.isRequired,
    className: PropTypes.string,
    active: PropTypes.bool.isRequired,
    toggleMenu: PropTypes.func.isRequired,
}

export default MobileNavi
