import React from 'react'
import { toast } from 'react-toastify'
import { ToastWrapper } from '../../styles/StyleConf'
import { TranslateString as t } from '../../utils/DictionaryUtils'
import Config from '../../config/Config'

const locale = 'fi' // TODO: add support for other locales

export const toastConnectionError = err => {
    if (err) console.error(err)

    toast.dismiss()
    return toast.error(
        <ToastWrapper>
            <h4>{t(locale, 'ui__error--action-failed')}</h4>
            <p>{t(locale, 'ui__error--try-again')}</p>
        </ToastWrapper>,
        { closeButton: true }
    )
}

export const toastErrorWithClose = message => {
    toast.dismiss()
    return toast.error(<ToastWrapper>{message}</ToastWrapper>, { closeButton: true })
}

export const toastErrorAutoclose = message => {
    toast.dismiss()
    return toast.error(<ToastWrapper>{message}</ToastWrapper>, {
        autoClose: Config.ui.toastTime.error,
    })
}

export const toastAutoclose = message => {
    toast.dismiss()
    return toast.success(<ToastWrapper>{message}</ToastWrapper>, {
        autoClose: Config.ui.toastTime.error,
    })
}

export const toastWithClose = component => {
    toast.dismiss()
    return toast.info(component, { closeButton: true })
}

export const toastInfo = message => {
    toast.dismiss()
    return toast.info(<ToastWrapper>{message}</ToastWrapper>)
}

export const toastUpdate = ({ toastId, message, type }) => {
    return toast.update(toastId, {
        render: <ToastWrapper>{message}</ToastWrapper>,
        type: type === 'error' ? toast.TYPE.ERROR : toast.TYPE.SUCCESS,
        autoClose: type === 'error' ? Config.ui.toastTime.error : Config.ui.toastTime.info,
    })
}
