export const getUserAccessLevels = user => {
    if (!user) return null
    return { groups: user.user_groups.map(n => Number(n.id)), isAdmin: user.id === 1 && user.username === 'admin' }
}

export const checkUsergroup = (userData, groupIds) => {
    if (userData.user_groups) {
        const groups = Object.values(userData.user_groups)
        return groups.some(item => groupIds.some(id => id === item.id))
    } else {
        console.error('User has no user_groups.')
        return false
    }
}
