import React from 'react'
import { Flex, Box } from 'reflexbox'

export const Row = ({ ...props }) => {
    const options = {
        ...Object.assign(
            {},
            {
                flexWrap: 'wrap',
                mx: [0, -2, -2],
                mb: 2,
            },
            props
        ),
    }

    return <Flex {...options} />
}

export const Col = ({ ...props }) => {
    const options = {
        ...Object.assign(
            {},
            {
                px: [0, 2, 2],
                flex: '1 1 auto',
            },
            props
        ),
    }

    return <Box {...options} />
}
