import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Logo from '../ui/Logo'
import OikotieLogo from '../ui/OikotieLogo'
import { installationFromUrl } from '../../utils/APIUtils'

class PlainWrapper extends React.Component {
    render() {
        const { children } = this.props

        return (
            <Wrapper>
                <Top>
                    <a href={`/${installationFromUrl()}`}>
                        <LargeLogo size={'large'} />
                    </a>
                    <PdxBorder />
                    {children}
                    <OikotieContainer>
                        <OikotieLogo />
                    </OikotieContainer>
                </Top>
            </Wrapper>
        )
    }
}

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
    margin-top: 5em;
    margin-right: 10%;
    width: 100%;
    align-items: center;
`

const Top = styled.div`
    display: flex;
    align-items: center;
    width: 80%;
    flex-direction: column;
    background: white;
    border-radius: 5px;
    box-shadow: 0 2px 0 0 rgb(0 0 0 / 5%);
`

const PdxBorder = styled.div`
    margin-top: 4em;
    height: 1px;
    width: 80%;
    background-color: #dddddd;
`

const OikotieContainer = styled.div`
    width: 255px;
    height: 100px;
    margin-top: 5em;
    padding-left: 6em;
    margin-bottom: 2em;
`

const LargeLogo = styled(Logo)`
    margin-top: 2em;
    display: flex;
`

PlainWrapper.propTypes = {
    children: PropTypes.object.isRequired,
}

export default PlainWrapper
