import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Dropzone from 'react-dropzone'
import Button from './Button'
import { Colors, TruncText } from '../../styles/StyleConf'
import { TranslateString as t } from '../../utils/DictionaryUtils'
import { withLocale } from '../../context/l10nContext'

const FilesInput = ({ locale, formik, id, isOnlyButton, file, callback, accept, isLocked, hideBorder }) => {
    const { setFieldValue, setFieldTouched } = formik

    const [filename, setFilename] = useState(file)

    return (
        <Dropzone
            multiple={false}
            onDrop={acceptedFiles => {
                const name =
                    acceptedFiles && acceptedFiles.length && acceptedFiles[0].name ? acceptedFiles[0].name : null
                setFieldValue(id, acceptedFiles[0])
                if (!isOnlyButton) setFieldTouched(id, true)
                setFilename(name)
                callback && callback(acceptedFiles[0])
            }}
            accept={accept}
        >
            {({ getRootProps, getInputProps, isDragActive }) => {
                return (
                    <Wrapper {...getRootProps()}>
                        <input {...getInputProps()} />
                        {
                            <Area
                                locale={locale}
                                active={!!isDragActive}
                                isOnlyButton={isOnlyButton}
                                filename={filename}
                                isLocked={isLocked}
                                hideBorder={hideBorder}
                            />
                        }
                    </Wrapper>
                )
            }}
        </Dropzone>
    )
}

const Wrapper = styled.div`
    outline: none;
    width: 100%;
`

FilesInput.propTypes = {
    locale: PropTypes.string.isRequired,
    formik: PropTypes.shape({
        setFieldValue: PropTypes.func.isRequired,
        setFieldTouched: PropTypes.func.isRequired,
    }).isRequired,
    id: PropTypes.string.isRequired,
    isOnlyButton: PropTypes.bool,
    file: PropTypes.string,
    callback: PropTypes.func,
    accept: PropTypes.string,
    isLocked: PropTypes.bool,
    hideBorder: PropTypes.bool,
}

export default withLocale(FilesInput)

const Area = ({ locale, active, filename, isOnlyButton, isLocked, hideBorder }) => {
    if (isOnlyButton) {
        return (
            <>
                {filename && <Filename isOnlyButton={isOnlyButton}>{filename}</Filename>}
                <UploadButton disabled={isLocked}>
                    {t(locale, 'assignment__label--oikotie-campaign-picture-button')}
                </UploadButton>
            </>
        )
    } else {
        return (
            <AreaWrapper active={active} hideBorder={hideBorder}>
                <FakeButton disabled={isLocked}>{t(locale, 'ui__general--browse')}...</FakeButton>
                {filename && <Filename>{filename}</Filename>}
            </AreaWrapper>
        )
    }
}

const AreaWrapper = styled.div`
    background-color: ${({ active }) => (active ? Colors.bgGray : Colors.brandWhite)};
    border: ${({ active, hideBorder }) =>
        hideBorder ? '' : active ? `1px dashed ${Colors.uiGray}` : `1px solid ${Colors.uiGray}`};
    width: 100%;
    height: 45px;
    display: flex;
    align-items: center;
`

const FakeButton = styled(Button)`
    && {
        pointer-events: none;
        margin: 4px;
        height: 35px;
        min-width: 90px;
    }
`

const UploadButton = styled(Button)`
    && {
        pointer-events: none;
        height: 35px;
        min-width: 90px;
    }
`

const Filename = styled.div`
    padding: 0 0.5em 0 1em;
    margin-bottom: ${({ isOnlyButton }) => (isOnlyButton ? '20px' : '0px')};
    ${TruncText};
`

Area.propTypes = {
    locale: PropTypes.string.isRequired,
    active: PropTypes.bool.isRequired,
    filename: PropTypes.string,
    isOnlyButton: PropTypes.bool,
    isLocked: PropTypes.bool,
}
