export default {
    api: {
        scheme: 'https',
        domain: process.env.REACT_APP_API_DOMAIN, // eslint-disable-line
        path: '/v1/',
        timeout: process.env.REACT_APP_API_TIMEOUT, // eslint-disable-line
        params: {
            dashboard: {
                leads: {
                    limit: 60,
                },
                news: {
                    limit: 20,
                },
            },
        },
    },
    vipunen: {
        scheme: 'https',
        domain: 'vipunen.pdx.fi',
    },
    select: {
        l10n: {
            noOptionsMessage: () => 'Ei tuloksia',
            placeholder: 'Valitse..',
            loading: () => 'Ladataan...',
        },
    },
    export: {
        pdf: {
            T: 10,
            R: 10,
            B: 10,
            L: 10,
            pagetype: 'A4',
            O: 'P',
            format: 'json',
        },
    },
    editor: {
        modules: {
            toolbar: [
                [{ header: [3, false] }],
                ['bold'],
                [{ list: 'ordered' }, { list: 'bullet' }, { script: 'sub' }, { script: 'super' }],
                ['clean'],
            ],
        },
        formats: ['header', 'bold', 'list', 'script', 'indent', 'bullet'],
    },
    chars: {
        nbts: ' ', // NON-BREAKING THIN SPACE
    },
    breakpoints: {
        mobile: 500,
        tablet: 768,
        desktop: 1100,
    },
    navigation: {
        default: [
            { key: 'dashboard', path: '/', title: 'ui__navi--dashboard' },
            {
                key: 'customers',
                title: 'ui__navi--customers',
                items: [
                    {
                        key: 'customers-search',
                        pageId: '103',
                        title: 'ui__navi--customers-search',
                    },
                    {
                        key: 'customers-leads',
                        pageId: '373',
                        title: 'ui__navi--customers-leads',
                    },
                ],
            },
            {
                key: 'assignments',
                path: '/assignments',
                title: 'ui__navi--assignments',
                items: [
                    { key: 'assignments-sales', path: '/assignments', title: 'ui__navi--assignments-sales' },
                    { key: 'assignments-rent', pageId: '270', title: 'ui__navi--assignments-rent' },
                    {
                        key: 'assignments-acquisition',
                        path: '/buyassignments',
                        title: 'ui__navi--assignments-acquisition',
                    },
                ],
            },
            {
                key: 'marketing',
                title: 'ui__navi--marketing',
                items: [
                    {
                        key: 'marketing-showing-times',
                        pageId: '242',
                        title: 'ui__navi--marketing-showing-times',
                    },
                    {
                        key: 'marketing-markets',
                        pageId: '356',
                        title: 'ui__navi--marketing-markets',
                    },
                    {
                        key: 'marketing-magazines',
                        pageId: '241',
                        title: 'ui__navi--marketing-magazines',
                    },
                ],
            },
            {
                key: 'intranet',
                title: 'ui__navi--intranet',
                items: [
                    {
                        key: 'intranet-internal-news',
                        pageId: '208',
                        title: 'ui__navi--intranet-internal-news',
                    },
                    {
                        key: 'intranet-calendar',
                        pageId: '209',
                        title: 'ui__navi--intranet-calendar',
                    },
                    {
                        key: 'intranet-offices',
                        pageId: '205',
                        title: 'ui__navi--intranet-offices',
                    },
                    {
                        key: 'intranet-person-search',
                        pageId: '203',
                        title: 'ui__navi--intranet-person-search',
                    },
                    {
                        key: 'intranet-legal',
                        pageId: '237',
                        title: 'ui__navi--intranet-legal',
                    },
                    {
                        key: 'intranet-document-archive',
                        pageId: '272',
                        title: 'ui__navi--intranet-document-archive',
                    },
                ],
            },
            {
                key: 'reports',
                title: 'ui__navi--reports',
                items: [
                    {
                        key: 'reports-power-bi',
                        pageId: '402',
                        title: 'ui__navi--reports-power-bi',
                        access: 3,
                    },
                    {
                        key: 'reports-rent-list',
                        pageId: '361',
                        title: 'ui__navi--reports-rent-list',
                    },
                    {
                        key: 'reports-assignments-acquisition',
                        pageId: '362',
                        title: 'ui__navi--reports-assignments-acquisition',
                    },
                    {
                        key: 'reports-terminated-targets',
                        pageId: '370',
                        title: 'ui__navi--reports-terminated-targets',
                    },
                    {
                        key: 'reports-sales-list',
                        pageId: '244',
                        title: 'ui__navi--reports-sales-list',
                    },
                    {
                        key: 'reports-journal',
                        pageId: '248',
                        title: 'ui__navi--reports-journal',
                    },
                    {
                        key: 'reports-brokerage',
                        pageId: '299',
                        title: 'ui__navi--reports-brokerage',
                    },
                    {
                        key: 'reports-sold-targets',
                        pageId: '344',
                        title: 'ui__navi--reports-sold-targets',
                    },
                    {
                        key: 'reports-events-by-customer',
                        pageId: '345',
                        title: 'ui__navi--reports-events-by-customer',
                    },
                    {
                        key: 'reports-acquisition-channels',
                        pageId: '346',
                        title: 'ui__navi--reports-acquisition-channels',
                    },
                    {
                        key: 'reports-bills',
                        pageId: '347',
                        title: 'ui__navi--reports-bills',
                    },
                    {
                        key: 'reports-sold-targets-by-type',
                        pageId: '348',
                        title: 'ui__navi--reports-sold-targets-by-type',
                    },
                ],
            },
            {
                key: 'settings',
                title: 'ui__navi--settings',
                items: [
                    {
                        key: 'settings-news',
                        pageId: '101',
                        title: 'ui__navi--settings-news',
                        access: 1,
                    },
                    {
                        key: 'settings-offices-admin',
                        pageId: '206',
                        title: 'ui__navi--settings-offices-admin',
                        access: 6,
                    },
                    {
                        key: 'settings-offices',
                        pageId: '296',
                        title: 'ui__navi--settings-offices',
                        access: 3,
                    },
                    {
                        key: 'settings-staff',
                        path: '/persons',
                        title: 'ui__navi--settings-staff',
                        access: 7,
                    },
                    {
                        key: 'settings-companies',
                        pageId: '250',
                        title: 'ui__navi--settings-companies',
                        access: 3,
                    },
                    {
                        key: 'settings-bills',
                        pageId: '246',
                        title: 'ui__navi--settings-bills',
                        access: 3,
                    },
                    {
                        key: 'settings-magazines',
                        pageId: '304',
                        title: 'ui__navi--settings-magazines',
                        access: 6,
                    },
                    {
                        key: 'settings-environment',
                        pageId: '369',
                        title: 'ui__navi--settings-environment',
                        access: 5,
                    },
                    {
                        key: 'settings-help',
                        pageId: '385',
                        title: 'ui__navi--settings-help',
                        access: 5,
                    },
                    {
                        key: 'settings-personal-info',
                        path: 'persons-self',
                        title: 'ui__navi--settings-personal-info',
                        access: 5,
                    },
                    {
                        key: 'settings-intranet',
                        pageId: '294',
                        title: 'ui__navi--settings-intranet',
                        access: 2,
                    },
                    {
                        key: 'settings-export',
                        pageId: '112',
                        title: 'ui__navi--settings-export',
                        access: 6,
                    },
                    {
                        key: 'settings-feedback',
                        pageId: '360',
                        title: 'ui__navi--settings-feedback',
                        access: 5,
                    },
                ],
            },
        ],
        construction: [
            { key: 'dashboard', path: '/construction/', title: 'ui__navi--dashboard' },
            { key: 'customers', path: '/construction/customers', title: 'ui__navi--customers' },
            { key: 'housing', path: '/construction/housing', title: 'ui__navi--housing' },
            { key: 'marketing', path: '/construction/marketing', title: 'ui__navi--marketing' },
            { key: 'intranet', path: '/construction/intranet', title: 'ui__navi--intranet' },
            { key: 'reports', path: '/construction/reports', title: 'ui__navi--reports' },
            { key: 'settings', path: '/construction/settings', title: 'ui__navi--settings' },
        ],
    },
    user: {
        storageKey: 'PDX_user',
    },
    ui: {
        modalId: 'modal-root',
        tooltipId: 'tooltip-root',
        noScrollClass: 'no-scroll',
        confirmationTime: 4000,
        toastTime: {
            info: 2500,
            error: 5000,
        },
    },
    validation: {
        password: {
            min: 12,
            max: 50,
        },
    },
    map: {
        api_key: process.env.REACT_APP_GOOGLE_MAP_JS_API_KEY,
    },
    assignment: {
        capitalTransferTax: {
            property: 4,
            share: 2,
        },
    },
    links: {
        diasIntroduction: 'https://asiakaspalvelu.dias.fi/',
        oikotieLink: 'https://www.oikotie.fi',
        pdxGeneralServiceTerms: 'https://static.asunnot.oikotie.fi/documents/pdx/PDX+Yleiset+Palveluehdot.pdf',
    },
    ng: {
        url: 'https://' + process.env.REACT_APP_NG_URL + '/#/',
    },
    constants: {
        stringConst: {
            ACCESS_LEVEL_SYSTEM_FILE: '9',
            ASSIGNMENT_TYPE_SALE: '1',
            ASSIGNMENT_TYPE_BUY: '2',
            ASSIGNMENT_PROPERTY: '0',
            ASSIGNMENT_SHARE: '1',
            BROCHURE_NORMAL_TEMPLATE: 'normal',
            BROCHURE_CUSTOM_TEMPLATE: 'custom',
            DEFAULT_PORTAL_CAMPAIGN_LINK_COLOR: '#0645ad',
            CUSTOMER_MODAL_MODE_EDIT: 'edit',
            CUSTOMER_MODAL_MODE_ADD_NEW: 'add',
            CUSTOMER_TYPE_MANDATOR: '1',
            CUSTOMER_TYPE_BUYER: '2',
            CUSTOMER_TYPE_SELLER: '3',
            DOCUMENT_TYPE_BROCHURE: '25',
            DOCUMENT_TYPE_DISPLAY_BROCHURE: '168',
            DOCUMENT_TYPE_PRICELIST: '169',
            SELECT_ID_CREATE_NEW: 'new',
            SALE_STATUS_INITIATED: '1',
            SALE_STATUS_ON_SALE: '2',
            SALE_STATUS_RESERVED: '3',
            SALE_STATUS_SOLD: '4',
            SALE_STATUS_CANCELLED: '5',
            VIEW_IMAGE: 'image',
            VIEW_LEGACY: 'legacy',
            VIEW_LIST: 'list',
            VIEW_MODAL: 'modal',
            VIEW_TABLE: 'table',
        },
        integerConst: {
            OBJECT_TYPE_ASSIGNMENT: 1,
            LONG_ERROR_MESSAGE_LIMIT: 38,
            MAXIMUM_AMOUNT_OF_CUSTOMERS: 50,
            PREVALIDATION_DELAY: 1500,
            REFERENCE_OBJECT_CUSTOMER: 5,
        },
    },
}
