import React from 'react'

export const UIContext = React.createContext({
    modal: null,
    toggleModal: () => {},
    userMenu: false,
    toggleUserMenu: () => {},
    mobileNavi: false,
    toggleMobileNavi: () => {},
})

export const withUIState = Component => {
    return function ComponentWithUIState(props) {
        return <UIContext.Consumer>{uiState => <Component {...props} {...uiState} />}</UIContext.Consumer>
    }
}
