import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Colors, Fonts } from '../styles/StyleConf'
import { withLocale } from '../context/l10nContext'
import { TranslateString as t } from '../utils/DictionaryUtils'
import Footer from './layout/Footer'
import Button from './input/Button'

const Error = ({ locale, type, format, text }) => {
    const errorTitle = t(locale, 'ui__error--connection-error')
    const errorPersistsText = t(locale, 'ui__error--proplem-persists')
    const supportContactEmail = 'Email: tuki@pdx.fi'
    const supportContactPhone = 'Puh. 010 800 820'
    let errorText
    let content

    switch (type) {
        case 'timeout':
            errorText = t(locale, 'ui__error--connection-timeout')
            break
        case 'connection':
            errorText = t(locale, 'ui__error--connection-missing')
            break
        default:
            errorText = text || t(locale, 'ui__error--action-failed')
    }

    switch (format) {
        default:
            content = (
                <Content>
                    <ErrorTitle>{errorTitle}</ErrorTitle>

                    <ErrorText>{`${errorText} ${errorPersistsText}`}</ErrorText>

                    <Contact>
                        {supportContactEmail}
                        <br />
                        <br />
                        {supportContactPhone}
                    </Contact>

                    <Button onClick={() => window.location.reload()}>{t(locale, 'ui__action--try-again')}</Button>
                </Content>
            )
    }

    return (
        <Wrapper>
            <Header>
                <Footer noTopMargin />
            </Header>

            <ErrorContainer>{content}</ErrorContainer>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    background-color: ${Colors.brandBlack};
`

const Header = styled.div`
    height: 100px;
`

const ErrorContainer = styled.div`
    background-color: ${Colors.brandHighlight};
    height: 660px;
`

const Content = styled.div`
    background-color: ${Colors.brandWhite};
    font-family: ${Fonts.brandFont};
    padding: 20px;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
`

const ErrorTitle = styled.h1`
    font-family: ${Fonts.headingFont};
    font-size: ${Fonts.heading};
    color: ${Colors.brandBlue};
`

const ErrorText = styled.p`
    font-size: ${Fonts.medium};
    color: ${Colors.brandBlack};
    margin-bottom: 20px;
    text-align: justify;
`

const Contact = styled.p`
    font-size: ${Fonts.large};
    margin-bottom: 30px;
`

Error.propTypes = {
    locale: PropTypes.string.isRequired,
    type: PropTypes.string,
    format: PropTypes.string,
    text: PropTypes.string,
}

export default withLocale(Error)
