import React from 'react'
import PropTypes from 'prop-types'
import ReactSelect from 'react-select'
import AsyncSelect from 'react-select/async'
import Config from '../../config/Config'
import { Colors, TextInput } from '../../styles/StyleConf'
import { withLocale } from '../../context/l10nContext'

const Select = ({
    locale,
    config,
    name,
    id,
    options,
    loadOptions,
    defaultOptions,
    onChange,
    onBlur,
    value,
    defaultValue,
    isSearchable,
    isClearable,
    isDisabled,
    isLoading,
    className,
    closeMenuOnSelect,
    hideSelectedOptions,
    isMulti,
    maxMenuHeight,
    legacy,
    placeholder,
    size,
}) => {
    const customOptions =
        locale === 'fi'
            ? config || {
                  loadingMessage: Config.select.l10n.loading,
                  noOptionsMessage: Config.select.l10n.noOptionsMessage,
                  placeholder: placeholder || Config.select.l10n.placeholder,
              }
            : null

    const customStyles = {
        control: (styles, { isDisabled }) => ({
            ...styles,
            minHeight:
                size && size === 'small' ? parseInt(TextInput.height.small, 10) : parseInt(TextInput.height.medium, 10),
            borderRadius: 2,
            borderColor: Colors.uiGray,
            boxShadow: `inset 0 ${!loadOptions ? '-3px' : '3px'} 0 0 rgba(0,0,0,0.05)`,
            backgroundColor: isDisabled ? Colors.bgGray : Colors.brandWhite,
            cursor: isSearchable && !isDisabled ? 'text' : 'default',
        }),
        container: styles => ({
            ...styles,
            height: size && size === 'small' ? TextInput.height.small : TextInput.height.medium,
            borderRadius: 2,
        }),
        dropdownIndicator: styles => ({
            ...styles,
            color: Colors.brandBlack,
            padding: size && size === 'small' ? '5px 8px' : '8px',
            display: loadOptions ? 'none' : 'inline-block',
            cursor: 'default',
        }),
        indicatorSeparator: styles => ({
            ...styles,
            display: loadOptions ? 'none' : 'inline-block',
        }),
        clearIndicator: styles => ({
            ...styles,
            color: Colors.brandBlack,
            cursor: 'default',
        }),
    }

    return loadOptions ? (
        <AsyncSelect
            name={name || id}
            id={name || id}
            loadOptions={loadOptions}
            defaultOptions={defaultOptions}
            cacheOptions
            {...customOptions}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={defaultValue}
            isSearchable={isSearchable}
            isClearable={isClearable}
            isDisabled={isDisabled}
            isLoading={isLoading}
            isMulti={isMulti}
            className={className}
            classNamePrefix={className ? 'react-select' : null}
            closeMenuOnSelect={closeMenuOnSelect}
            hideSelectedOptions={hideSelectedOptions}
            maxMenuHeight={maxMenuHeight}
            legacy={legacy}
            styles={customStyles}
            key={name}
        />
    ) : (
        <ReactSelect
            name={name || id}
            id={id}
            options={options}
            {...customOptions}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            defaultValue={defaultValue}
            isSearchable={isSearchable}
            isClearable={isClearable}
            isDisabled={isDisabled}
            isLoading={isLoading}
            isMulti={isMulti}
            className={className}
            classNamePrefix={className ? 'react-select' : null}
            closeMenuOnSelect={closeMenuOnSelect}
            hideSelectedOptions={hideSelectedOptions}
            maxMenuHeight={maxMenuHeight}
            legacy={legacy}
            styles={customStyles}
        />
    )
}

Select.propTypes = {
    name: PropTypes.string,
    id: PropTypes.string,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            label: PropTypes.string,
        })
    ),
    defaultOptions: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            label: PropTypes.string,
        })
    ),
    loadOptions: PropTypes.func,
    config: PropTypes.object,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func,
    value: PropTypes.oneOfType([
        PropTypes.shape({
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            label: PropTypes.string,
        }),
        PropTypes.string,
    ]),
    defaultValue: PropTypes.oneOfType([
        PropTypes.shape({
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            label: PropTypes.string,
        }),
        PropTypes.string,
    ]),
    isSearchable: PropTypes.bool,
    isClearable: PropTypes.bool,
    isDisabled: PropTypes.bool,
    isLoading: PropTypes.bool,
    locale: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    className: PropTypes.string,
    legacy: PropTypes.bool,
    size: PropTypes.string,
    closeMenuOnSelect: PropTypes.bool,
    hideSelectedOptions: PropTypes.bool,
    isMulti: PropTypes.bool,
    maxMenuHeight: PropTypes.number,
    placeholder: PropTypes.string,
}

Select.defaultProps = {
    isClearable: true,
    isSearchable: false,
    closeMenuOnSelect: true,
    hideSelectedOptions: false,
}

export default withLocale(Select)
