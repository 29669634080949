import React from 'react'
import styled, { css } from 'styled-components'
import { Colors, Fonts, UI, TextInput } from '../../styles/StyleConf'

export const Input = styled.input.attrs(props => props)`
    ${props =>
        props.type === 'checkbox' || props.type === 'radio'
            ? css`
                  &:disabled + label {
                      opacity: 0.5;
                  }
                  cursor: pointer;
                  width: 20px;
                  height: 20px;
              `
            : css`
                  &:disabled {
                      background-color: ${Colors.bgGray};
                  }
                  cursor: text;
                  width: 100%;
                  height: ${TextInput.height.medium};
                  border: 1px solid ${Colors.uiGray};
                  font-size: ${Fonts.medium};
                  padding: 0 0.5em;
                  ${UI.inputShadow};
              `};

    ${props =>
        props.disabled &&
        css`
            cursor: default;
        `};

    vertical-align: middle;
    user-select: none;
`

const StyledTextArea = styled.textarea`
    && {
        width: 100%;
        height: 160px;
        padding: 0.6em 0.6em;
        font-size: ${Fonts.medium};
        border-color: ${Colors.uiGray};
        border-radius: 2px;
        ${UI.inputShadow};
    }
`

export const TextArea = props => <StyledTextArea {...props} />

export const RequiredTag = styled.sup`
    display: inline-block;
    color: ${Colors.errorRed};
    margin-left: 0.2em;
    position: absolute;
`
